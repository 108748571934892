<template>
  <div class="add-vehicle">
    <confirm-change-menu @close="conformDialog = false" :dialog="conformDialog" @confirm="goToNext()" />
    <confirmation-dialog @close="dialogDelete = false, clickedPart = {}" :dialog="dialogDelete"
      @confirm="deleteCarParts()" />
    <div class="page-header mt-3" id="page-header">
      <b-row class="w-100">
        <b-col md="12" sm="12" lg="6" class="mb-2 w-100">
          <b-form-group id="input-group-7" label-for="input-7" style="margin-bottom: 0px;">
            <b-input-group class="search-group">
              <b-form-input id="input-7" type="text" :placeholder="$t('commonLayout.search')" v-model="search">
              </b-form-input>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                  stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M19 19L14.7 14.7" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </svg>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="add-forms">
      <div class="accordion" id="accordionExample">
        <div class="card" v-for="(part, i) in alreadyAddedParts" :key="part.id"
          v-if="search.length <= 1 || (search.length >= 2 && part.Description.toLowerCase().search(search.toLowerCase()) != -1)">
          <div class="card-header" :id="'headingOne-' + part.id">
            <div v-if="isLoader && part.Description == clickedPart.Description" class="spinner-border" role="status"
              style="width: 1rem;height: 1rem;color:#18541d;"></div>
            <span><span class="remove close" v-if="part.Description != clickedPart.Description" @click="onRemove(part)">
              <span data-v-57924770="" class="remove icon-plus-minus"> - </span>
            </span>
            <label class="part_label">{{ part.Description }}</label></span>
            <span class="open_part_label" @click="clickParts(part)" data-toggle="collapse"
              :data-target="'#collapseOne-' + part.id" aria-expanded="true" :aria-controls="'collapseOne-' + part.id">
              <span v-if="isPartCheckout(part) == ''"><svg width="13" height="7" viewBox="0 0 13 7" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 0.75L5.79289 5.54289C6.18342 5.93342 6.81658 5.93342 7.20711 5.54289L12 0.75"
                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                <span class="hint-opt">{{ $t('addPart.optional') }}  </span> {{ $t('addPart.optional_hint') }}
                
              </span>
              <span v-else class="hint--red">{{ isPartCheckout(part) }}</span>
            </span>
          </div>
          <div :id="'collapseOne-' + part.id" class="collapse" :aria-labelledby="'headingOne-' + part.id"
            data-parent="#accordionExample">
            <div class="card-body">
              <b-row>
                <b-col md="12" sm="12" lg="6">
                  <b-form v-if="selectedPart && selectedPart.id == part.id">
                    <b-row>
                      <b-col md="12" sm="12" lg="12">
                        <div><span id="msg"></span></div>
                        <div class="upload-images-wrapper row">
                          <div class="col-md-12">
                            <label class="d-block">{{ $t('addPart.part_image') }} </label>
                          </div>
                          <div class="upload-images col-md-12">
                            <b-form-group id="input-group-01" label-for="input-01">
                              <image-uploader ref="img1" id="fileInput" :quality="0.9" outputFormat="verbose"
                                :preview=false :className="['hidden']" @input="onFileChange($event, 'img1')"
                                @onUpload="onFileChangeUpload" @onComplete="onFileChangeComplete">
                                <label for="fileInput" slot="upload-label" class="node-image-uploader">
                                  <figure>
                                    <svg data-v-7e1bed0e="" data-v-74177b85="" width="24" height="24"
                                      viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path data-v-7e1bed0e="" data-v-74177b85=""
                                        d="M12 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H6C6.53043 7 7.03914 6.78929 7.41421 6.41421C7.78929 6.03914 8 5.53043 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5C16 5.53043 16.2107 6.03914 16.5858 6.41421C16.9609 6.78929 17.4696 7 18 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V12.5M16 19H22M19 16V22"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                      <path data-v-7e1bed0e="" data-v-74177b85=""
                                        d="M9 13C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7956 10 12 10C11.2044 10 10.4413 10.3161 9.87868 10.8787C9.31607 11.4413 9 12.2044 9 13Z"
                                        stroke="black" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    </svg>
                                  </figure>
                                  <span class="upload-caption" style="padding-left: 10px;padding-bottom: 10px;">{{ $t('AddCar.click_to_upload') }} 
                                      <span style="color: red;">( {{ $t('addPart.default_part_image') }} )</span></span>
                                </label>
                              </image-uploader>
                              <div class="d-flex flex-wrap gap-10">
                                <span style="position: unset;" class="hint">
                                  {{ $t('addPart.social_hint1') }} 
                                  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">Facebook</a>,
                                  <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">Instagram</a>, the 
                                  <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">Redline Inventory</a>
                                  {{ $t('addPart.social_hint2') }}
                                </span>
                              </div>
                            </b-form-group>
                            <div class="img-preview">
                              <div class="img-loader d-none">
                                <div class="vld-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" stroke="#4CAF50">
                                    <g fill="none" fill-rule="evenodd">
                                      <g transform="translate(1 1)" stroke-width="2">
                                        <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
                                        <path d="M36 18c0-9.94-8.06-18-18-18">
                                          <animateTransform attributeName="transform" type="rotate" from="0 18 18"
                                            to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform>
                                        </path>
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <div class="img-pre-box">
                                <div id="img1" class="select-image select-image-div">
                                </div>
                                <a id="img1-delIcon" @click="deleteImage('img1')" class="delicon" data-tooltip="Delete">
                                  <svg data-v-74177b85="" width="7" height="7" viewBox="0 0 7 7" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path data-v-74177b85=""
                                      d="M1.12136 1.12132L5.364 5.36396M5.364 1.12132L1.12136 5.36396" stroke="red"
                                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-8" :label="$t('addPart.interchange')" label-for="input-8">
                          <b-form-input :disabled="!data.part_name" id="input-8" type="text" :placeholder="$t('addPart.interchange')"
                          v-model="data.interchange">
                        </b-form-input>
                          <!-- <b-form-select v-if="!isInterchangeLoader" id="input-8" type="text" class="form-control"
                            :placeholder="$t('addPart.select_interchange')" v-model="data.interchange"
                            :disabled="(!data.part_name || (data.part_name && interchangeList.length == 0))"
                            @change="onChangeInterchange()"
                            :class="(!data.interchange && isInterchangeRequired) ? 'error-message' : ''">
                            <option :value="null" v-if="interchangeList.length == 0">{{
                              $t("addPart.select_no_interchange_found") }}</option>
                            <option :value="null" v-if="interchangeList.length > 0">{{
                              $t("addPart.select_interchange") }}</option>
                            <template v-for="(interchange, index) in interchangeList">
                              <template>
                                <option :v-if="interchange.IntchNbr"
                                  v-bind:class="[interchange.IntchNbr ? 'font-weight-bold mb-3' : '']" :key="index + 1"
                                  :value="interchange.InterchangeNumber" :disabled="!interchange.IntchNbr">
                                  {{ interchange.Application }}&nbsp;&nbsp;&nbsp;&nbsp;
                                  {{ interchange.InterchangeNumber ? '(' + interchange.InterchangeNumber + ')' : '' }}
                                </option>
                              </template>
                            </template>
                          </b-form-select>
                          <span v-if="isInterchangeLoader" class="text-danger">
                            <span class="spinner-border spinner-border-sm" role="status"
                              aria-hidden="true"></span>&nbsp;
                            Loading...
                          </span> -->
                        </b-form-group>
                        <!-- <span class="errMsg" v-if="isInterchangeRequired && !data.interchange">{{
                          $t("validations.interchangeIsRequired")
                        }}</span> -->
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-7" :label="$t('addPart.recommendedSales')" label-for="input-7">
                          <b-input-group>
                            <b-input-group-prepend>
                              <span class="input-group-text">{{ '' | CurrencySymbol(country) }}</span>
                            </b-input-group-prepend>
                            <b-form-input :disabled="!data.part_name" id="input-7" type="number"
                              :placeholder="$t('addPart.recommendedSales')" v-model="data.recommended_sale_price"
                              @keypress="validCost($event)">
                            </b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-8" :label="$t('addPart.partGrade')" label-for="input-8">
                          <b-form-select :disabled="!data.part_name" id="input-8" type="text" class="form-control"
                            :options="gradeOptions" :placeholder="$t('addPart.partGrade')" v-model="data.part_grade"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-10" :label="$t('addPart.warranty')" label-for="input-10">
                          <b-form-select :disabled="!data.part_name" id="input-10" type="number" class="form-control"
                            :options="warrantyOptions" v-model="data.warranty_days"
                            :placeholder="$t('addPart.warranty')"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-10" :label="$t('addPart.description')" label-for="input-10">
                          <b-form-textarea id="textarea" :placeholder="$t('addPart.write_here')" rows="3"
                            v-model="data.description" max-rows="6" @input="$v.data.description.$touch()"
                            @blur="$v.data.description.$touch()" required :class="$v.data.description.$dirty &&
                              $v.data.description.$invalid
                              ? 'error-message'
                              : ''
                              "></b-form-textarea>
                        </b-form-group>
                        <span class="errMsg"> {{ descriptionErrors[0] }}</span>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                          <button type="button" class="btn custom-btn pattern-btn float-right" value="Continue" v-if="!isImageLoader" @click="save"><span>{{ $t('addPart.continue') }}</span></button>
                        <button class="btn custom-btn pattern-btn float-right" v-if="isImageLoader" disabled="true">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                          {{ $t('addPart.processing') }}
                        </button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div class="card" v-for="(part, i) in partsSettings" :key="part.id"
          v-if="(search.length <= 1 || (search.length >= 2 && part.Description.toLowerCase().search(search.toLowerCase()) != -1)) && (alreadyAddedParts.map(i => i.Description)).includes(part.Description) === false">
          <div class="card-header" :id="'headingOne-' + part.id">
            <div v-if="isLoader && part.Description == clickedPart.Description" class="spinner-border" role="status"
              style="width: 1rem;height: 1rem;color:#18541d;"></div>
            <span class="add close icon-plus-minus" v-if="part.Description != clickedPart.Description"
              @click="onAdd(part)">
              +
            </span>
            <label class="part_label">{{ part.Description }}</label>
          </div>
          <div :id="'collapseOne-' + part.id" class="collapse" :aria-labelledby="'headingOne-' + part.id"
            data-parent="#accordionExample">
            <div class="card-body">
              <b-row>
                <b-col md="6" sm="12" lg="6">
                  <b-form v-if="selectedPart && selectedPart.id == part.id">
                    <b-row>
                      <b-col md="12" sm="12" lg="12">
                        <div><span id="msg"></span></div>
                        <div class="upload-images-wrapper row">
                          <div class="col-md-12">
                            <span class=""><b>{{ $t('addPart.part_image') }} </b></span> <br>
                            <span style="position: unset;">
                              {{ $t('addPart.social_hint1') }}  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">Facebook</a>,
                              <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">Instagram</a>, the <a
                                :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">Redline Inventory</a> {{ $t('addPart.social_hint2') }} 
                            </span>
                          </div>
                          <div class="upload-images col-md-12">
                            <b-form-group id="input-group-01" label-for="input-01" style="height: 100px;">
                              <image-uploader ref="img1" id="fileInput" :quality="0.9" outputFormat="verbose"
                                :preview=false :className="['hidden']" @input="onFileChange($event, 'img1')"
                                @onUpload="onFileChangeUpload" @onComplete="onFileChangeComplete">
                                <label for="fileInput" slot="upload-label" class="node-image-uploader">
                                  <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                      <path class="path1"
                                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z">
                                      </path>
                                    </svg>
                                  </figure>
                                  <span class="upload-caption" style="padding-left: 10px;padding-bottom: 10px;">{{ $t('AddCar.click_to_upload') }}
                                    <span style="color: red;">({{ $t('addPart.default_part_image') }})</span></span>
                                </label>
                              </image-uploader>
                            </b-form-group>
                            <div id="img1" class="select-image" style="padding-top: 30px;"></div>
                            <a id="img1-delIcon" @click="deleteImage('img1')" class="delicon" data-tooltip="Delete">
                              <img src="../assets/delete.svg" height="30" />
                            </a>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-8" label="Interchange" label-for="input-8">
                          <b-form-input :disabled="!data.part_name" id="input-8" type="text" :placeholder="$t('addPart.interchange')"
                            v-model="data.interchange">
                          </b-form-input>
                          <!-- <b-form-select v-if="!isInterchangeLoader" id="input-8" type="text" class="form-control"
                            :placeholder="$t('addPart.select_interchange')" v-model="data.interchange"
                            :disabled="(!data.part_name || (data.part_name && interchangeList.length == 0))"
                            @change="onChangeInterchange()"
                            :class="(!data.interchange && isInterchangeRequired) ? 'error-message' : ''">
                            <option :value="null" v-if="interchangeList.length == 0">{{
                              $t("addPart.select_no_interchange_found") }}</option>
                            <option :value="null" v-if="interchangeList.length > 0">{{
                              $t("addPart.select_interchange") }}
                            </option>
                            <template v-for="(interchange, index) in interchangeList">
                              <template>
                                <option :v-if="interchange.IntchNbr"
                                  v-bind:class="[interchange.IntchNbr ? 'font-weight-bold mb-3' : '']" :key="index + 1"
                                  :value="interchange.InterchangeNumber" :disabled="!interchange.IntchNbr">
                                  {{ interchange.Application }}&nbsp;&nbsp;&nbsp;&nbsp;
                                  {{ interchange.InterchangeNumber ? '(' + interchange.InterchangeNumber + ')' : '' }}
                                </option>
                              </template>
                            </template>
                          </b-form-select>
                          <span v-if="isInterchangeLoader" class="text-danger">
                            <span class="spinner-border spinner-border-sm" role="status"
                              aria-hidden="true"></span>&nbsp;
                            Loading...
                          </span> -->
                        </b-form-group>
                        <!-- <span class="errMsg" v-if="isInterchangeRequired && !data.interchange">{{
                          $t("validations.interchangeIsRequired") }}</span> -->
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-7" :label="$t('addPart.recommendedSales')" label-for="input-7">
                          <b-input-group>
                            <b-input-group-prepend>
                              <span class="input-group-text">{{ '' | CurrencySymbol(country) }}</span>
                            </b-input-group-prepend>
                            <b-form-input :disabled="!data.part_name" id="input-7" type="number"
                              :placeholder="$t('addPart.recommendedSales')" v-model="data.recommended_sale_price"
                              @keypress="validCost($event)">
                            </b-form-input>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-8" :label="$t('addPart.partGrade')" label-for="input-8">
                          <b-form-select :disabled="!data.part_name" id="input-8" type="text" class="form-control"
                            :options="gradeOptions" :placeholder="$t('addPart.partGrade')" v-model="data.part_grade"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-10" :label="$t('addPart.warranty')" label-for="input-10">
                          <b-form-select :disabled="!data.part_name" id="input-10" type="number" class="form-control"
                            :options="warrantyOptions" v-model="data.warranty_days"
                            :placeholder="$t('addPart.warranty')"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <b-form-group id="input-group-10" :label="$t('addPart.description')" label-for="input-10">
                          <b-form-textarea id="textarea" :placeholder="$t('addPart.write_here')" rows="3"
                            v-model="data.description" max-rows="6" @input="$v.data.description.$touch()"
                            @blur="$v.data.description.$touch()" required :class="$v.data.description.$dirty &&
                              $v.data.description.$invalid
                              ? 'error-message'
                              : ''
                              "></b-form-textarea>
                        </b-form-group>
                        <span class="errMsg"> {{ descriptionErrors[0] }}</span>
                      </b-col>
                      <b-col md="12" sm="12" lg="12">
                        <input type="button" class="btn custom-btn float-right" @click="save" value="Continue"
                          v-if="!isImageLoader">
                        <button class="btn custom-btn float-right" v-if="isImageLoader" disabled="true">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                          {{ $t('addPart.processing') }}
                        </button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import moment from "moment";
import {
  required,
  between,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { showSuccess, showFailure } from "../eventbus/action.js";
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import ConfirmChangeMenu from "../components/ConfirmChangeMenu";
import ConfirmationDialog from "../components/ConfirmationDialog";

export default {
  components: {
    ConfirmationDialog,
    ConfirmChangeMenu
  },
  data() {
    return {
      VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
      VUE_APP_RDL_INVENTORY_LINK: process.env.VUE_APP_RDL_INVENTORY_LINK,
      image: {},
      data: {
        interchange: null,
        make: null,
        model: null,
        year: null,
        miles: null,
        part_name: null,
        part_grade: null,
        quantity: null,
        description: null,
        recommended_sale_price: "",
        warranty_days: null,
        // select_interchange_text: "",
      },
      imageResponseArray: [],
      gradeOptions: [
        { text: this.$t("commonLayout.select"), value: null },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
      ],
      warrantyOptions: [
        { text: this.$t("commonLayout.select"), value: null },
        { text: "AS IS", value: "AS IS" },
        { text: "5 "+this.$t("commonLayout.days"), value: "5 "+this.$t("commonLayout.days") },
        { text: "15 "+this.$t("commonLayout.days"), value: "15 "+this.$t("commonLayout.days") },
        { text: "30 "+this.$t("commonLayout.days"), value: "30 "+this.$t("commonLayout.days") },
        { text: "60 "+this.$t("commonLayout.days"), value: "60 "+this.$t("commonLayout.days") },
        { text: "90 "+this.$t("commonLayout.days"), value: "90 "+this.$t("commonLayout.days") },
      ],
      conformDialog: false,
      isInterchangeRequired: false,
      year: null,
      make: {},
      model: {},
      selectedPart: null,
      alreadyAddedParts: [],
      dialogDelete: false,
      selectedDeleteId: null,
      selectedDeletePartName: null,
      isLoader: false,
      clickedPart: {},
      isImageLoader: false,
      isInterchangeLoader: false,
      search: "",
      country: 'us'
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    "$i18n.locale": function(nVal, oVal) {
      if(nVal != oVal) {
        this.gradeOptions = [
          { text: this.$t("commonLayout.select"), value: null },
          { text: "A", value: "A" },
          { text: "B", value: "B" },
          { text: "C", value: "C" },
        ],
        this.warrantyOptions = [
          { text: this.$t("commonLayout.select"), value: null },
          { text: "AS IS", value: "AS IS" },
          { text: "5 "+this.$t("commonLayout.days"), value: "5 "+this.$t("commonLayout.days") },
          { text: "15 "+this.$t("commonLayout.days"), value: "15 "+this.$t("commonLayout.days") },
          { text: "30 "+this.$t("commonLayout.days"), value: "30 "+this.$t("commonLayout.days") },
          { text: "60 "+this.$t("commonLayout.days"), value: "60 "+this.$t("commonLayout.days") },
          { text: "90 "+this.$t("commonLayout.days"), value: "90 "+this.$t("commonLayout.days") },
        ];
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      carDetailData: "carlist/getCarDetail",
      partsSettings: "partlist/getPartsSettings",
      mediaImageResponse: "partlist/getMediaImageResponse",
      interchangeList: "partlist/getInterchange",
      carPart: "partlist/getCarPart",
      auctionPart: "partlist/getAuctionPart",

      partsData: "partlist/getPartById",
    }),
    partGradeErrors() {
      const errors = [];
      if (!this.$v.data.part_grade.$dirty) return errors;
      !this.$v.data.part_grade.required &&
        errors.push(this.$t("validations.partGradeIsRequired"));
      return errors;
    },
    quantityErrors() {
      const errors = [];
      if (!this.$v.data.quantity.$dirty) return errors;
      !this.$v.data.quantity.between &&
        errors.push(this.$t("validations.quantityIsBetween"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.data.description.$dirty) return errors;
      !this.$v.data.description.maxLength &&
        errors.push(this.$t("validations.desMaxLength"));
      return errors;
    },
  },
  validations: {
    data: {
      part_name: { required },
      quantity: { between: between(1, 10) },
      description: { maxLength: maxLength(150) },
    },
  },
  destroyed() {
    $("li.car-wrapper > a").removeClass(
      "router-link-exact-active router-link-active"
    );
  },
  async mounted() {
    this.country = await this.user?.user_detail?.country || 'us';
    await this.setPartsSetting();
    await this.getCarDetails();
    setTimeout(() => {
      $("li.car-wrapper > a").addClass(
        "router-link-exact-active router-link-active"
      );
    }, 10);
    window.scrollTo({ top: 0, behavior: "smooth" });
  },

  // updated() {
  //   if (this.interchangeList.length < 1) {
  //     this.data.select_interchange_text = this.$t("partDialog.select_no_interchange_found");
  //     this.data.interchange = null;
  //   } else {
  //     this.data.select_interchange_text = this.$t("partDialog.select_interchange");
  //   }
  // },

  methods: {
    ...mapActions({
      carDetail: "carlist/getCarDetail",
      getPartsSetting: "partlist/getPartsSetting",
      uploadMediaImage: "partlist/uploadMediaImage",
      updateVehicleParts: "partlist/updateVehicleParts",
      getCarPart: "partlist/getCarPart",
      getInterchange: "partlist/getInterchange",
      addAuctionParts: "partlist/addAuctionParts",

      partsById: "partlist/getPartsById",
      deleteCarPart: "partlist/deleteCarPart",
    }),
    validateForm() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        return true;
      } else {
        return false;
      }
    },
    validCost(e) {
      if(this.data.recommended_sale_price) {
        const validate = this.data.recommended_sale_price.split(".");
        if (validate[1] && validate[1].length >= 2) {
          e.preventDefault();
        }
        const reg = /[^0-9]/;
        if (reg.test(e.key)) {
          e.preventDefault();
        }
      }
    },
    async setPartsSetting() {
      try {
        await this.getPartsSetting();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },
    convertUSCurrency() {
      let currency = 0;
      if (this.data.recommended_sale_price) {
        currency = Number(
          this.data.recommended_sale_price.toString().replace(/[^0-9/.]/g, "")
        )
          .toString()
          .split(".");
        if (!currency[1]) {
          this.data.recommended_sale_price = currency[0]
            .replace(/\D/g, "")
            .split("")
            .reverse()
            .reduce(function (acc, num, i) {
              return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "");
        } else {
          this.data.recommended_sale_price =
            currency[0]
              .replace(/\D/g, "")
              .split("")
              .reverse()
              .reduce(function (acc, num, i) {
                return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
              }, "") +
            "." +
            currency[1];
        }
      }
    },
    async getCarDetails() {
      try {
        this.$store.commit("setLoader", true);
        await this.carDetail(this.$route.query.id);
        this.data.miles = this.carDetailData.miles;
        this.data.make = {
          MfrCd: this.carDetailData.make_code,
          MfrName: this.carDetailData.make,
          label: this.carDetailData.make + '(' + this.carDetailData.make_code + ')',
        }
        this.data.model = {
          ModelNm: this.carDetailData.model,
          label: this.carDetailData.model,
        }
        this.data.year = this.carDetailData.year;
        this.data.miles = this.carDetailData.miles;

        this.year = this.carDetailData.year;
        this.make = this.data.make;
        this.model = this.data.model;

        var parts = await this.carDetailData.car_parts.map(function (el) {
          return el.part_name
        });
        this.alreadyAddedParts = await this.partsSettings.filter(p => parts.includes(p.Description));
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async getCarPartDetails(partName) {
      try {
        // this.$store.commit("setLoader", true);
        await this.getCarPart({ car_profile_id: this.$route.query.id, part_name: partName });
        if (this.carPart) {
          this.data = Object.assign({}, this.carPart);
          this.data.interchange = this.carPart.interchange_code ? this.carPart.interchange_code : null;
          // await this.onChangeInterchange();
          if (this.carPart.medias) {
            this.data.medias.forEach((val) => {
              $(`#img1`).append(`<img class=img1 src='' alt=''>`);
              this.image[val.tag] = {
                data: val,
              };
              $(`.img1`).attr("src", val.url100);
              $(`#img1-delIcon`).show();
            });
          }
        } else {
          this.data.interchange = null;
          this.data.part_grade = null;
          this.data.quantity = null;
          this.data.description = null;
          this.data.recommended_sale_price = "";
          this.data.warranty_days = null;
          // this.data.select_interchange_text = "";
          this.image = {};

          $(`#img1-delIcon`).hide();
        }
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        // showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
      }
    },
    async setInterchange() {
      try {
        // this.$store.commit("setLoader", true);
        this.isInterchangeLoader = true;
        this.interchange = null;
        if (this.year && this.make && this.model && this.selectedPart) {
          await this.getInterchange({
            BeginYear: this.year,
            MfrCd: this.make.MfrCd,
            ModelNm: this.model.ModelNm,
            PartType: this.selectedPart.PartType
          });
        }
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object"
            ? Object.values(message)[0][0]
            : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        this.isInterchangeLoader = false;
      }
    },
    // onChangeInterchange() {
    //   if (this.interchangeList && this.interchangeList.length != 0) {
    //     this.isInterchangeRequired = true;
    //     // this.data.interchange = newInterchange;
    //   } else {
    //     this.isInterchangeRequired = false;
    //   }
    // },
    deleteImage(type) {
      $(`#${type}`).empty();
      $(`#${type}-delIcon`).hide();
      this.image = {};
      this.$refs[type].$el.children[1].value = "";
    },
    async saveImage(file, tag) {
      try {
        // this.$store.commit("setLoader", true);
        this.isImageLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        await this.uploadMediaImage(formData);
        // showSuccess("Image Saved Successfully");
        return this.mediaImageResponse;
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        this.isImageLoader = false;
      }
    },
    async onFileChange(fileData, type) {
      $(`#${type}`).empty();
      $(`#${type}`).append(`<img class=${type} src='${fileData.dataUrl}' alt=''>`);
      this.image[type] = await this.saveImage(fileData.dataUrl, type);
      $(`.${type}`).attr("src", this.image[type].data.url100);
      $(`#${type}-delIcon`).show();
    },
    async onFileChangeComplete(data) {
      console.log("complete", data);
    },
    async onFileChangeUpload(data) {
      console.log("upload", data);
    },
    changeRouter(page) {
      // this.$router.push({name: "Car list"});
      this.$router.push({ name: "Edit Vehicle", params: { id: this.$route.query.id } });
    },
    async save() {
      this.imageResponseArray = [];
      Object.keys(this.image).forEach((val) => {
        this.imageResponseArray.push(this.image[val]);
      });
      // if (this.interchangeList.length > 0) {
      //   this.isInterchangeRequired = true;
      // }
      this.$v.data.$touch();
      if ((this.isInterchangeRequired == true && typeof this.data.interchange == 'string') || this.isInterchangeRequired == false) {
        try {
          this.$store.commit("setLoader", true);
          let payload = Object.assign({}, this.data);
          payload.car_profile_id = this.$route.query.id;

          payload.make = this.make.MfrName;
          payload.make_code = this.make.MfrCd;
          payload.model = this.model.ModelNm;
          payload.model_code = this.model.ModelNm;

          payload.part_name = this.selectedPart.Description;
          payload.part_number = this.selectedPart.PartType;
          payload.part_id = this.selectedPart.PartType;

          payload.interchange_code = this.data.interchange;
          payload.media_ids = this.imageResponseArray.map((val) => val.data.id);
          payload.is_bulk_part = true;

          if (this.carPart && this.carPart.id) {
            await this.addAuctionParts({
              ...payload,
            });
          }
          await this.setPartsSetting();
          this.clickParts(this.selectedPart);
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
    conformDialogOpen(next) {
      this.conformDialog = true;
      this.link = next;
    },
    goToNext() {
      this.conformDialog = false;
      this.link();
    },
    async clickParts(part) {
      if (part?.Description == this.selectedPart?.Description) {
        $(".collapse").removeClass("show");
        this.selectedPart = null;
        this.data.part_name = null;
        this.data.interchange = null;
        this.data.part_grade = null;
        this.data.quantity = null;
        this.data.description = null;
        this.data.recommended_sale_price = "";
        this.data.warranty_days = null;
        // this.data.select_interchange_text = "";
        this.image = {};
      } else {
        this.selectedPart = part;
        this.data.part_name = part;
        $(".collapse").removeClass("show");
        $(`#collapseOne-${part.id}`).addClass('show');
        // await this.setInterchange();
        $(`#img1-delIcon`).hide();
        await this.getCarPartDetails(part.Description);
      }
    },
    async onAdd(part) {
      try {
        // this.$store.commit("setLoader", true);
        this.isLoader = true;
        this.clickedPart = part;
        let payload = {};
        payload.car_profile_id = this.$route.query.id;
        payload.part_name = part.Description;
        payload.part_number = part.PartType;
        payload.media_ids = [];
        payload.is_bulk_part = true;
        await this.addAuctionParts({
          ...payload
        });

        if (this.selectedPart) {
          this.clickParts(this.selectedPart);
        }
        this.alreadyAddedParts.push(part);
        this.carDetailData.car_parts.push(this.auctionPart);
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object"
            ? Object.values(message)[0][0]
            : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        this.isLoader = false;
        this.clickedPart = {};
      }
    },
    async onRemove(part) {
      this.clickedPart = part;
      var parts = this.carDetailData.car_parts.find(function (i) {
        return i.part_name == part.Description;
      });
      this.selectedDeleteId = parts.id;
      this.selectedDeletePartName = part.Description;
      this.dialogDelete = true;
    },
    async deleteCarParts() {
      this.dialogDelete = false;
      try {
        // this.$store.commit("setLoader", true);
        this.isLoader = true;
        await this.deleteCarPart(this.selectedDeleteId);

        if (this.selectedPart) {
          this.clickParts(this.selectedPart);
        }

        var partName = this.selectedDeletePartName;
        this.alreadyAddedParts = this.alreadyAddedParts.filter(function (i) {
          return i.Description != partName;
        });
        var partId = this.selectedDeleteId;
        this.carDetailData.car_parts = this.carDetailData.car_parts.filter(function (i) {
          return i.id != partId;
        });
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        this.isLoader = false;
        this.clickedPart = {};
      }
    },
    handleScroll() {
      var header = document.getElementById("page-header");
      if(header) {
        var sticky = header.offsetTop;
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
        } else {
          header.classList.remove("sticky");
        }
      }
    },
    isPartCheckout(part) {
      var searchedPart = this.carDetailData.car_parts.filter(p => p.part_name == part.Description && p.checkout_at != null);
      if (searchedPart.length > 0) {
        var checkout_at = moment(searchedPart[0].checkout_at).format("MM/DD/YY");
        return '(Part has been checked Out on ' + checkout_at + '. Click here to add it again)';
      } else {
        return "";
      }
    }
  },
};
</script>

